import { Calculation } from '@fincalc/Entities/Calculation.entity';

export const isStandardFinancing = (calculation: Calculation | null) =>
  calculation?.selectedProduct === 'standardFinancing';

export const isLeasingFinancing = (calculation: Calculation | null) =>
  calculation?.selectedProduct === 'privateLeasing';

export const isThreeWayFinancing = (calculation: Calculation | null) =>
  calculation?.selectedProduct === 'threeWayFinancing';

export const isTwowayFinancing = (calculation: Calculation | null) =>
  calculation?.selectedProduct === 'twoWayFinancing';
